import { useRef, useEffect, useState } from 'react';

function useElementHeight(): [React.RefObject<HTMLDivElement>, number, React.Dispatch<React.SetStateAction<number>>] {
	const [elementHeight, setElementHeight] = useState(0);
	const elementRef = useRef(null);

	useEffect(() => {
		if (elementRef.current?.offsetHeight) {
			const updateHeight = () => {
				setElementHeight(elementRef.current.offsetHeight);
			};

			window.addEventListener('resize', updateHeight);
			updateHeight();

			return () => {
				window.removeEventListener('resize', updateHeight);
			};
		}
	}, [elementRef.current]);

	return [elementRef, elementHeight, setElementHeight];
}

export default useElementHeight;
